.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  margin-top: 65px;
  min-height: 100vh;
}

p, div, h1, h2, h3, img, ::placeholder {
  margin: 0;
  padding: 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

a {
  text-decoration: none;
}

/* Search */

.autocompleteInput {
  position: relative;
}
.autocompleteSearchOptionsWrapper {
  padding: 12px;
  width: 400px;
  position: absolute;
  left: 0;
  top: 35;
  background-color: white;
  text-align: left;
  list-style: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100vh;
  overflow: scroll;
}

.autocompleteSearchItem {
  border-bottom: 1px solid lightgrey;
  padding: 6px;
  display: flex;
  justify-content: space-between;
}

.autocompleteSearchItem:hover {
  cursor: pointer;
  color: #0377C6;
}

.autocompleteLocationCode .autocompleteAddress {
  text-align: left;
}

.autocompleteAddress {
  max-width: 215px;
}

.createTicketFormItem {
  margin: 20px 0;
  width: 100%;
}

.notesTextArea {
  width: 100%;
  min-height: 70px;
  padding: 8px;
}

/* Login */

.loginTitle {
  margin-bottom: 12px;
}

.loginButton {
  background-color: #0377C6;
  width: 200px;
}

.fusaLogoLogin {
  margin-bottom: 16px;
}

.loginButton:hover {
  cursor: pointer;
}

/* Signup */

.signup {
  margin-top: 100px;
}

.signupInputsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputAuth {
  margin: 12px 0;
  width: 400px;
  border-radius: 10px;
}

/* Navigation  */

/* .active {
  background-color: #0379C5;
  border-radius: 5p6;
  color: white;
} */

.active li {
  border-bottom: 3px solid #0377C6;
  border-radius: 0;
}


.navWrapper {
  width: 100%;
  height: 65px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: white;
}

.fusaLogo {
  width: 220px;
  height: auto;
  padding-left: 20px;
}

.fusaLogo:hover {
  cursor: pointer;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  height: 65px;
  list-style: none;
}

.navItem {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 18px;
  padding: 12px;
  margin: 12px;
  border-radius: 5px;
  text-decoration: none;
  color: black;
}

.navItem:hover {
  cursor: pointer;
  border-bottom: 3px solid #0377C6;
  border-radius: 0;
}

tr:hover {
  cursor: pointer;
  background-color: rgba(3, 119, 198, .5) !important;
}

/* Homepage  */

.homepage {
  max-width: 100%;
  padding: 0 20px;
}

.heading {
  text-align: left;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 24px;
  font-weight: normal;
  padding: 20px 0;
}

#tableWrapper{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tableWrapper td, #tableWrapper th {
  border: 1px solid #ddd;
  padding: 8px;
}

#tableWrapper tr:nth-child(even){background-color: #f2f2f2;}

#tableWrapper tr:hover {background-color: #ddd;}

#tableWrapper th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #666666;
  color: white;
}

.userManualWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 90px 0 50px 0;
  text-align: left;
}

/* Utils  */

.labelAndStarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.requiredInfoWarning {
  color: tomato;
}

.requiredStar {
  color: tomato;
}

.Ticket, .schedule, .homepage, .district, .location, .schedules, .locations, .signup {
  min-height: calc(100vh - 500px);
}

.searchInputWrapper {
  margin-bottom: 24px;
}
 

::placeholder {
  font-size: 18px;
  color: grey;
}

input, select {
  padding: 18px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid grey;
  box-shadow: 1px 1px 1px lightgrey;
  font-size: 18px;
  box-sizing: border-box;
}

.mainButton {
  background-color: #0377C6;
  border-radius: 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 12px;
  border: none;
  color: white;
  font-size: 22px;
  max-width: 300px;
  margin: 20px 0;
  text-align: center;
}

.mainButton:hover {
  cursor: pointer;
  opacity: 0.8;
}

.inputWrapper {
  width: 400px;
}

.textArea {
  width: 400px;
  min-height: 70px;
}

.fieldLabel {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 12px 0;
  font-weight: bold;
}

.field {
  background: #F5F5F5;
  padding: 10px;
  box-sizing: border-box;
}

h2 {
  margin: 20px 0;
}

/* Footer  */

.footer {
  width: 100%;
  min-height: 300px;
  background-color: #36454F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.footerItem {
  margin: 6px 0;
}

.footerContentWrapper {
  color: white;
}

/* districts */

.districtDetailsWrapper, .locationDetailsWrapper, .scheduleDetailsWrapper, .ticketsList, .ticketDetailsWrapper {
  margin: 100px 20px 20px 20px;
  text-align: left;
}

.arrow {
  color: lightgrey;
}

.locationsList, .schedulesList {
  margin: 0 20px;
  text-align: left;
}

.schedulesList {
  margin: 0 20px;
  text-align: left;
}

/* Service Tickets */

.inputLabel {
  text-align: left;
  font-weight: bold;
  margin: 6px 0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ticketsInputWrapper {
  width: 400px;
}

.ticketsActionsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serviceTicketWrapper {
  padding: 40px 70px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
}

.district, .location, .schedule, .schedules, .locations, .serviceTicket, .tickets {
  margin: 100px 20px 0 20px;
  min-height: 100vh;
}

/* Remove default styles */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #555;
  padding: 12px;
  padding-right: 40px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-image: linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);
  background-repeat: no-repeat;
  background-position: right 10px center;
}

/* Add custom styles */
select::after {
  content: "\f107";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #aaa;
}

/* Hover and focus styles */
select:hover, select:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-color: #aaa;
}
